import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { WagmiConfig } from "wagmi";
import Navbar from "./components/Navbar";
import { PROJECT_ID, ethereumClient, wagmiConfig } from "./utils/utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import { Web3Modal } from "@web3modal/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <ChakraProvider>
        <ToastContainer />
        <Navbar />
        <App />
        <Footer />
      </ChakraProvider>
      <Web3Modal
        themeVariables={{
          "--w3m-accent-color": "#00e3c9",
          "--w3m-background-color": "#00e3c9",
          "--w3m-accent-fill-color": "#fff",
        }}
        projectId={PROJECT_ID}
        ethereumClient={ethereumClient}
      />
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
