import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";

function Footer() {
  const socialMedia = [
    {
      name: "Twitter",
      icon: "fa-brands fa-twitter fa-xl",
      link: "https://twitter.com/LinkdaoN",
    },
    {
      name: "Telegram",
      icon: "fa-brands fa-telegram fa-xl",
      link: "https://t.me/linkdao_network",
    },
    {
      name: "Medium",
      icon: "fa-brands fa-medium fa-xl",
      link: "https://medium.com/@linkdaonetwork",
    },
    {
      name: "Github",
      icon: "fa-brands fa-github fa-xl",
      link: "https://github.com/linkdaonetwork",
    },
    {
      name: "Gitbook",
      icon: "fa-solid fa-book fa-xl",
      link: "https://linkdao-network.gitbook.io/linkdao",
    },
    {
      name: "Email",
      icon: "fa-solid fa-envelope fa-xl",
      link: "mailto:contact@linkdao.network",
    },
  ];

  const renderSocialLinks = () => {
    const openLink = (link: any) => {
      window.open(link, "_blank");
    };

    return socialMedia.map((platform, index) => (
      <Flex
        cursor={"pointer"}
        key={index}
        gap="6px"
        align="center"
        minW={{ base: "44%", md: "fit-content" }}
        onClick={() => openLink(platform.link)}
      >
        <i className={platform.icon} />
        <Text>{platform.name}</Text>
      </Flex>
    ));
  };

  const CustomText = ({ text, link }: any) => {
    return (
      <Text
        cursor={"pointer"}
        mb="10px"
        fontSize={["14px", "18px"]}
        onClick={() => window.open(link)}
      >
        {text}
      </Text>
    );
  };
  return (
    <>
      {/* <Box
        bg="#EFF0F6"
        borderTopRadius={{ base: "30px", md: "80px" }}
        pt="15px"
        pb="70px"
      >
        <Flex
          justify={{ base: "space-between", md: "space-around" }}
          color={"#7770E0"}
          fontSize={"16px"}
          maxW={"1320px"}
          mx="auto"
          wrap={"wrap"}
          gap="20px"
          px={{ base: "15px", md: "30px" }}
        >
          {renderSocialLinks()}
        </Flex>
      </Box> */}
      <Box
        bgImage={"footerBG.png"}
        // bg='linear-gradient(183deg,#472876 30%,#2d1e61 70%)'
        bgSize={"cover"}
        borderTopRadius={{ base: "20px", md: "60px" }}
        mt="15px"
        pb={["20px", "40px"]}
      >
        <Image
          h={["45px", "65px"]}
          src="logo.svg"
          maxW="90%"
          pt="20px"
          ml="15px"
          mb="-30px"
          display={{ md: "none" }}
        />
        <Flex
          maxW={"1320px"}
          mx="auto"
          pt="50px"
          px="15px"
          color={"white"}
          justify={"space-between"}
          wrap={"wrap"}
          gap="20px"
        >
          <Image
            h="45px"
            src="logo.svg"
            display={{ base: "none", md: "block" }}
          />
          <Box>
            <Heading
              mb="20px"
              fontWeight={"normal"}
              fontSize={["20px", "28px"]}
            >
              About Us
            </Heading>
            <CustomText
              text="RoadMap"
              link="https://linkdao-network.gitbook.io/linkdao/protocol/roadmap"
            />
            <CustomText
              text="Security"
              link="https://linkdao-network.gitbook.io/linkdao/development/security"
            />
            <CustomText
              text="Tokenomics"
              link="https://linkdao-network.gitbook.io/linkdao/protocol/tokenomics"
            />
            <CustomText
              text="Contact Us"
              link="https://linkdao-network.gitbook.io/linkdao/contacts/contacts"
            />
          </Box>
          <Box>
            <Heading
              mb="20px"
              fontWeight={"normal"}
              fontSize={["20px", "28px"]}
            >
              Solutions
            </Heading>
            <CustomText
              text="Earn"
              link="https://linkdao-network.gitbook.io/linkdao/introduction/staking-and-earning"
            />
            <CustomText text="LKD Tools" link="https://app.linkdao.network/" />
            <CustomText
              text="Buy LKD"
              link="https://linkdao.network/#/BuyLKD"
            />
            <CustomText
              text="LKD Token"
              link="https://linkdao-network.gitbook.io/linkdao/introduction/lets-get-started"
            />
          </Box>
          <Box>
            <Heading
              mb="20px"
              fontWeight={"normal"}
              fontSize={["20px", "28px"]}
            >
              Learn
            </Heading>
            <CustomText text="How to Buy LKD" link="https://linkdao.network/" />
            <CustomText text="How to Stake" link="https://linkdao.network/" />
          </Box>
        </Flex>
        <Divider mb="0px" color={"white"} maxW="1320px" mx="auto" />
        <Flex
          justify={"space-between"}
          align={"center"}
          maxW="1320px"
          mx="auto"
          color={"white"}
          px="15px"
          wrap={"wrap"}
          gap="20px"
        >
          <Text fontSize={["10px", "12px"]}>
            Copyright © Linkdao 2023. All rights reserved.
          </Text>
          <Flex gap="10px">
            <Image
              src="coinMarketCap.svg"
              cursor={"pointer"}
              onClick={() =>
                window.open(
                  "https://coinmarketcap.com/currencies/linkdao-network/"
                )
              }
            />
            <Image
              src="coinGecko.svg"
              cursor={"pointer"}
              onClick={() =>
                window.open("https://www.coingecko.com/en/coins/linkdao")
              }
            />
            <Image
              src="disclaimer.svg"
              cursor={"pointer"}
              onClick={() =>
                window.open("https://linkdao.network/#/Disclaimer")
              }
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

export default Footer;
